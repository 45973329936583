import React, { useEffect, useState } from 'react';
import styles from './PostCard.module.css';

const PostCard = (props) => {
    let bgColor, boxShadow;
    if ("bgColor" in props) {
        bgColor = props["bgColor"];
        boxShadow = "5px 5px 1px 3px rgba(151, 151, 169, 0.089)";
    } else {
        bgColor = "white";
        boxShadow = "none";
    }

    

    return (
        <div style={{backgroundColor: bgColor, boxShadow: boxShadow}} className={styles.PostCard}>
            {props.postTitle}
            <br></br>{props.postDesc}
        </div>
    )
};

export default PostCard;