import React from "react";
import Profile from "./profile/Profile";
import Title from "./components/Title";
import PostCard from "../boards/PostCard";

const Home = () => {
    const style = {
        padding: "0",
        margin: "0",
        padding: "0"
    };

    return (
        <div style={style}>
            <Profile />
            {/* <Title text="Services"></Title>
            <div style={{display: "grid", gridTemplateColumns:"1fr 1fr 1fr", rowGap:"15px", columnGap:"15px"}}>
                <PostCard bgColor="rgb(236, 236, 236)" postTitle="title" postDesc="desc"></PostCard>
                <PostCard bgColor="rgb(236, 236, 236)" postTitle="title" postDesc="desc"></PostCard>
                <PostCard bgColor="rgb(236, 236, 236)" postTitle="title" postDesc="desc"></PostCard>
            </div>
            <Title text="Hot Articles"></Title>
            <div style={{display: "grid", gridTemplateColumns:"1fr 1fr 1fr", rowGap:"15px", columnGap:"15px"}}>
                <PostCard postTitle="title" postDesc="desc"></PostCard>
                <PostCard postTitle="title" postDesc="desc"></PostCard>
                <PostCard postTitle="title" postDesc="desc"></PostCard>
                <PostCard postTitle="title" postDesc="desc"></PostCard>
                <PostCard postTitle="title" postDesc="desc"></PostCard>
            </div>
            <Title text="New Articles"></Title>
            <div style={{display: "grid", gridTemplateColumns:"1fr 1fr 1fr", rowGap:"15px", columnGap:"15px"}}>
                <PostCard postTitle="title" postDesc="desc"></PostCard>
                <PostCard postTitle="title" postDesc="desc"></PostCard>
                <PostCard postTitle="title" postDesc="desc"></PostCard>
                <PostCard postTitle="title" postDesc="desc"></PostCard>
                <PostCard postTitle="title" postDesc="desc"></PostCard>
                <PostCard postTitle="title" postDesc="desc"></PostCard>
                <PostCard postTitle="title" postDesc="desc"></PostCard>
                <PostCard postTitle="title" postDesc="desc"></PostCard>
                <PostCard postTitle="title" postDesc="desc"></PostCard>
            </div>
            <Title text="Recommended Articles"></Title>
            <div style={{display: "grid", gridTemplateColumns:"1fr 1fr 1fr", rowGap:"15px", columnGap:"15px"}}>
                <PostCard postTitle="title" postDesc="desc"></PostCard>
                <PostCard postTitle="title" postDesc="desc"></PostCard>
                <PostCard postTitle="title" postDesc="desc"></PostCard>
                <PostCard postTitle="title" postDesc="desc"></PostCard>
                <PostCard postTitle="title" postDesc="desc"></PostCard>
                <PostCard postTitle="title" postDesc="desc"></PostCard>
            </div>
            <Title text="External Links"></Title>
            <div style={{display: "grid", gridTemplateColumns:"1fr 1fr 1fr", rowGap:"15px", columnGap:"15px"}}>
                <PostCard postTitle="title" postDesc="desc"></PostCard>
                <PostCard postTitle="title" postDesc="desc"></PostCard>
                <PostCard postTitle="title" postDesc="desc"></PostCard>
                <PostCard postTitle="title" postDesc="desc"></PostCard>
                <PostCard postTitle="title" postDesc="desc"></PostCard>
                <PostCard postTitle="title" postDesc="desc"></PostCard>
            </div> */}
        </div>
    );
};

export default Home;