import React from "react";
import styles from "./Profile.module.css";

const Profile = () => {
    return (
        <div className={styles.Profile}>
            <h1 style={{paddingTop:"0px", marginTop:"0px"}}>Designed by zangzini in Gyeonggi-do</h1>
            <table>
                <thead></thead>
                <tbody>
                <tr>
                    <td style={{width:"130px"}}>MBTI type is</td>
                    <td>ESTP</td>
                </tr>
                <tr>
                    <td>Interested in</td>
                    <td>Morphology, Design, Programming</td>
                </tr>
                <tr>
                    <td>Love and enjoy</td>
                    <td>Working out, Games, Traveling, Movie</td>
                </tr>
                </tbody>
                <tfoot></tfoot>
            </table>
            <br></br>
            {/* <a className={styles.profileA} href="https://blog.naver.com/zangzini" target="_blank">네이버 블로그</a>와 <a className={styles.profileA} href="https://zangzini.tistory.com" target="_blank">티스토리</a>에서도 저를 만나볼 수 있어요.
            <br></br>
            <a className={styles.profileA} href="https://supplier.tistory.com" target="_blank">저품질 광고 블로그</a>도 운영하고 있답니다. */}
            <a className={styles.profileA} href="https://blog.naver.com/zangzini" target="_blank">맛집 블로그</a>
            <br></br>
            <a className={styles.profileA} href="https://blog.naver.com/zangzini_" target="_blank">개인 블로그</a>
            <br></br>
            <a className={styles.profileA} href="https://ddoongi.tistory.com" target="_blank">구 기술 블로그</a>
            <br></br>
            <a className={styles.profileA} href="https://zangzini.tistory.com" target="_blank">신 기술 블로그</a>
            <br></br>
            <a className={styles.profileA} href="https://supplier.tistory.com" target="_blank">저품질 광고 블로그</a>

        </div>
    );
};

export default Profile;