import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PostCard from './PostCard';

const Boards = () => {
    const { boardName } = useParams();
    const [posts, setPosts] = useState([]);

    const getPosts = () => {
        return [
            {
                key: "k1",
                title: "title1",
                description: "desc1"
            },
            {
                key: "k2",
                title: "title2",
                description: "desc2"
            }
        ];
    };

    useEffect(() => {
        setPosts(getPosts());
    }, []);

    return (
        <div>
            게시판 이름: {boardName}
            <br></br>
            {posts.map(item => <PostCard key={item.key} postTitle={item.title} postDesc={item.description}></PostCard>)}
        </div>
    )
};

export default Boards;