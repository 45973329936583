import { logDOM } from "@testing-library/react";
import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import MarkdownView from "react-showdown";

const Post = () => {
    const { postId } = useParams();
    const [post, setPost] = useState("");

    
    const path = require(`./tech/${postId}/content.md`);
        
    
    fetch(path)
        .then(response => response.text())
        .then(text => {
            setPost(text)
        });

    

    return (
        <div>
            this page {postId}...
            
            <MarkdownView
              markdown={post}
              options={{ tables: true, emoji: true }}
            />
        </div>
    )
};

export default Post;